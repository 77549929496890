/**
 * Navigation
 */

class PageNavigation {

    constructor(element) {

        if (!(element instanceof HTMLElement)) {
            throw new TypeError('Invalid element');
        }

        this.container = element;
        this.offsetHeight = 0;
        this.links = Array.from(this.container.querySelectorAll('a'));

        this.initSectionTriggerEvents();

        this.updatePosition.bind(this);
        window.addEventListener('scroll', this.updatePosition.bind(this));
    }

    /** Lock the page nave to top of the page */
    updatePosition () {
        // Offset before making page nav fixed, should be height of header (Primary Navigation) element
        this.offsetHeight = this.container.previousElementSibling.id === 'header'
            ? this.container.previousElementSibling.offsetHeight : 0;

        this.container.classList.toggle('fixed', window.scrollY >= this.offsetHeight);
    }

    initSectionTriggerEvents() {
        let offset = 114 + 52;
        let sections = Array.from(document.querySelectorAll('section'));

        sections = sections.filter(el => typeof el.id !== undefined && el.id !== null);
        sections = sections.filter(el => el.id !== "");
        sections.reverse();

        window.addEventListener('scroll', () => {
            // Check which section is currently in focus

            let focus = sections.map(el => {
                return {
                    "id": el.id,
                    "focused": window.scrollY >= el.offsetTop - offset,
                };
            });

            focus = focus.filter(({focused}) => focused);

            if (focus.length > 0) {
                this.links.forEach(el => el.classList.toggle('active', false));
                this.links.forEach(el => {

                    let url = new URL(el.href);

                    el.classList.toggle('active', url.hash === `#${focus[0].id}`);
                });
            } else {
                // Set all sections as inactive
                this.links.forEach(el => el.classList.toggle('active', false));
            }
        });
    }
}

export default {
    init: function() {
        this.initMenuToggle();
        this.initMobileSubmenuToggle();
        this.initCampaignHeader();

        const pageNavigation = document.getElementById('page-navigation');

        if (pageNavigation) {
            pageNavigation.instance = new PageNavigation(pageNavigation);
        }
    },
    initMenuToggle: function () {
        // Toggle for mobile navigation
        document.querySelectorAll('.nav-trigger').forEach(el => {
            el.addEventListener('click', e => {
                e.preventDefault();

                e.currentTarget.classList.toggle('open');
                document.getElementById('menus').classList.toggle('open');
                document.getElementsByTagName('html')[0].classList.toggle('nav-open');
            });
        });
    },
    initMobileSubmenuToggle: function () {
        const primaryMenus = document.getElementById('menus');

        if (primaryMenus) {
            const menuItemsWithChildren = primaryMenus.querySelectorAll('.menu-item-has-children');

            // For each menu item with children
            for (let i = 0; i < menuItemsWithChildren.length; i++) {
                menuItemsWithChildren[i].addEventListener('click', e => {
                    if (e.target.tagName != 'A') {
                        // When clicked toggle active class, and remove active class from other items
                        let isActive = e.currentTarget.classList.contains('active');

                        menuItemsWithChildren.forEach(el => el.classList.toggle('active', false));
                        e.currentTarget.classList.toggle('active', !isActive);
                    }
                });
            }
        }
    },
    initCampaignHeader: function() {
        window.addEventListener('DOMContentLoaded', e => this.initScrollingNav());
        window.addEventListener('scroll', e => this.initScrollingNav());
    },
    initScrollingNav: function() {
        let mainHeaderElement = document.querySelector('.header-campaign');
        if (mainHeaderElement) {
            mainHeaderElement.classList.toggle('scrolling', mainHeaderElement.getBoundingClientRect().top < 0);
        }
    },
}
