/* globals jQuery */

import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import '@dotlottie/player-component';

import Choices from 'choices.js';
import GatedVideo from './modules/gatedVideo';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Nav from './modules/nav';
import Accordion from './modules/accordion';
import Timeline from './modules/timeline';
import TransparentizeVideo from './modules/transparentizeVideo';
import WOW from 'wow.js';
import { CountUp } from 'countup.js';
import map from './modules/map';
import mapEditor from './modules/mapEditor';
import mapSmbLandscape from './modules/mapSmbLandscape';
import Swipers from './modules/swipers';
import accordionSlider from './modules/accordionSlider';
import headers from './modules/headers';
import navTaxonomyComponent from './modules/navTaxonomyComponent';

import codatMaps from './modules/codatMaps';
import upcomingPosts from './modules/upcomingPosts';
import greenhouseHandler from './modules/greenhouseHandler';

import { iframeResizer } from "iframe-resizer";

// lazySizes.cfg.loadedClass = 'loaded-image';
// lazySizes.cfg.loadingClass = 'loading-class';
// lazySizes.cfg.throttleDelay = 66;

window.codat = {
    init: function () {

        // I am a wee bit baffled but IE11 is being awkward as normally and needs 3 lines to limit to 2???????????
        let lineCount = (!!window.MSInputMethodContext && !!document.documentMode) ? 3 : 2;
        // new AnchorBlock('.logo-link__link-text', true, false, lineCount);
        Nav.init();
        Swipers.init();
        accordionSlider.init();
        Timeline.init();
        upcomingPosts.init();
        TransparentizeVideo.init();
        codatMaps.init();
        map.init('map', 'map__btn', 630);
        mapEditor.init();
        mapSmbLandscape.init();
        GatedVideo.init();
        greenhouseHandler.init();
        headers.init();
        navTaxonomyComponent.init();

        // autosize iframes
        window.addEventListener('DOMContentLoaded', () => {
            iframeResizer({}, '.iframe-resize');
        });

        // wow.js and animate.css used for site ix
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: false,
            live: false,
            callback: box => {

                //box.classList.add('hit');

                if (box.classList.contains('lottieanimation')) {
                    box.play();
                }

                if (box.classList.contains('countUp')) {
                    let options = {
                        duration: 2.6,
                        easingFn: function (t, b, c, d) {
                            let ts = (t /= d) * t,
                                tc = ts * t;

                            return b + c * (tc + -3 * ts + 3 * t);
                        }
                    }
                    let stat = box.querySelector('[data-figure]');

                    if (typeof stat.dataset.figure !== 'undefined') {
                        let number = stat.dataset.figure.replace(/[^\d\.\-]/g, "");

                        options.decimalPlaces = (number.indexOf('.') === -1) ? 0 : number.split('.')[1].length;

                        if (typeof stat.dataset.prefix !== 'undefined') {
                            options.prefix = stat.dataset.prefix;
                        }
                        if (typeof stat.dataset.suffix !== 'undefined') {
                            options.suffix = stat.dataset.suffix;
                        }

                        let demo = new CountUp(stat, parseFloat(number), options);

                        if (!demo.error) {
                            demo.start();
                        } else {
                            console.warn(demo.error);
                        }
                    } else {
                        console.log('countUp num undefined');
                    }

                }
            }
        });
        wow.init();

        // Init accordions
        document.querySelectorAll('.accordion.accordion-faq, .tabbed-faqs-section .accordion')
            .forEach(el => {
                el.accordion = new Accordion(el);
            });

        document.querySelectorAll('.dropdown-select').forEach(el => {
            el.choices = new Choices(el, {
                searchEnabled: false,
                shouldSort: false,
                itemSelectText: '',
            });
        });

        // Init Caldera Dropdown with ChoicesJs
        document.querySelectorAll('.caldera-dropdown').forEach(el => {
            let selectEl = el.getElementsByTagName('select')[0];
            if (selectEl) {
                new Choices(selectEl, {
                    searchEnabled: false,
                    shouldSort: false,
                    itemSelectText: '',
                });
            }
        });

        let comparison_headers = document.querySelectorAll('.comparison-list-section .list__heading');
        let comparison_header_height = 0;
        comparison_headers.forEach(header => {
            if (header.getBoundingClientRect().height > comparison_header_height) {
                comparison_header_height = header.getBoundingClientRect().height;
            }
        })

        comparison_headers.forEach(header => {
            header.style.height = comparison_header_height + "px";
        })

        let comparison_image_containers = document.querySelectorAll('.comparison-list-section .feature__image');
        let comparison_image_container_height = 0;
        comparison_image_containers.forEach(container => {
            if (container.getBoundingClientRect().height > comparison_image_container_height) {
                comparison_image_container_height = container.getBoundingClientRect().height;
            }
        })

        comparison_image_containers.forEach(container => {
            container.style.height = comparison_image_container_height + "px";
        })

        // Features Comparison Section - Toggle mobile view
        document.querySelectorAll('.features-comparison-section').forEach(section => {
            let features = section.querySelectorAll('.feature');
            features.forEach(element => {
                element.addEventListener('click', event => {
                    if (event.target.tagName != 'A') {
                        event.currentTarget.classList.toggle('open');
                    }
                });
            });
        });

        // Video play button
        document.querySelectorAll('.video-wrapper').forEach(el => {
            el.addEventListener('click', e => {
                e.preventDefault();
                // console.log(e.target);

                if (e.target.parentElement.classList.contains('video-wrapper--playing')) {
                    e.target.pause();
                } else {
                    e.target.play();
                }
                e.target.parentElement.classList.toggle('video-wrapper--playing');
            });
        });

        // Adjust article share sidebar position
        const sidebar = document.querySelector('.article-share');
        const articleHeader = document.querySelector('.article__header');

        if (articleHeader) {
            let headerHeight = articleHeader.getBoundingClientRect().bottom - articleHeader.getBoundingClientRect().top;
            sidebar.style.marginTop = Math.round(headerHeight) + 42 + 'px'; // 42 is article header bottom margin
        }

        //Add standard class to caldera form validation and fix errorContainer to handle class change
        // window.Parsley.on('form:init', form => {
        // 	form.options.errorTemplate = '<span></span>';
        // 	form.options.errorsWrapper = '<span class="form__validation help-block caldera_ajax_error_block"></span>';
        // 	form.options.errorWrapper = '<span></span>';
        // 	form.options.errorsContainer = field => field.$element.closest('.form__group');
        // });

        //Allow user to interrupt auto-scrolling
        $(document).bind('scroll mousedown wheel keyup', function (e) {
            if (e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
                $(document.scrollingElement).stop();
            }
        });
    },

    initMaps: function () {
        if (document.readyState === 'complete') {
            // The page is fully loaded
            codatMaps.initMaps();
        } else {
            document.onreadystatechange = () => {
                if (document.readyState === 'complete') {
                    // document ready
                    codatMaps.initMaps();
                }
            };
        }
    },

    // Prefer creating new modules when appropriate, rather than adding functions here
    // If you need something to be globally accessible, you can assign an imported module to this namespace
    //  i.e. import Contact from './contact';
    //    window.namespace.Contact = Contact;
};

window.codat.init();

if (typeof NodeList.prototype.forEach !== 'function') {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
// eslint-disable-next-line no-unused-vars
const __sprite__ = {
    path: '../../resources/images/icons/**/*.svg', //Source SVGs path relative to the compiled js (dist/js)
    name: 'images/icons.svg', //Compiled file path relative to dist/
};

svgxhr({
    filename: './wp-content/themes/class/dist/images/icons.svg', //URL to fetch
});
