/** Map SMB Landscape */

class MapSMBLandscape {

    container = null;
    default_height = 0;

    constructor(element, default_height = 0) {

        this.container = element;
        this.default_height = default_height;

        var mapImage = this.container.querySelector('.map__picture');

        this.initOnMarkerHoverEvents();

        // Scale map with screen
        this.resizeMap(this.container, window.innerWidth, mapImage);
        window.addEventListener('resize', () => {
            this.resizeMap(this.container, window.innerWidth, mapImage);
        });
    }

    /**
     * Position marker x if content box is positioned out of view
     *
     * @param event
     */
    updateMarkerPosition(event) {
        let paddingOffset = '40px';
        let markerContentBox = this.querySelector('.marker__details');

        // Set Position Left Value to default value
        markerContentBox.style.left = 'calc(50%)';

        let viewportOffset = markerContentBox.getBoundingClientRect();
        let leftOffset = viewportOffset.left;
        let rightOffset = window.innerWidth - viewportOffset.right;

        // If the marker is outside the viewport,
        //  then shift the marker content box left or right dependent on the offset

        if (leftOffset < 0) {
            // Shift element to left by offset
            markerContentBox.style.left = `calc(50% + ${leftOffset * -1}px + ${paddingOffset})`;
        }

        if (rightOffset < 0) {
            // Shift element to right by offset
            markerContentBox.style.left = `calc(50% - ${rightOffset * -1}px - ${paddingOffset}`;
        }
    }

    initOnMarkerHoverEvents() {
        // Check that there are markers on the map
        if (this.container.querySelector('.map__marker')) {
            Array.from(this.container.querySelectorAll('.map__marker'))
                .forEach(marker => {
                    // Create event listener for each marker
                    marker.addEventListener('mouseenter', this.updateMarkerPosition.bind(marker));
                });
        }
    }

    //margin right to centre contents (when origin centre) -36rem
    //.map__image * { margin-left: -36rem }
    resizeMap(element, width, map_image) {
        // console.log("Screen width: " + width);
        if (width < 1200 && element) {
            const small_phone = 350;
            const iphone_6_plus = 414;
            const ipad_mini = 768
            const container_size = 1240;
            // console.log('mobile')
            if (width >= small_phone && width < iphone_6_plus) {
                // console.log('iPhone 6');
                // $(element).css({transform = "scale(0.3)"});
                element.style.left = "50%";
                element.style.transform = "translateX(-50%) scale(0.275)";
                // element.style.marginLeft = '-11rem';
                this.recalculateMapHeight(element, map_image, 0.275);
            } else if (width >= iphone_6_plus && width < ipad_mini) {
                element.style.left = "50%";
                element.style.transform = "translateX(-50%) scale(0.37)";
                // element.style.marginLeft = '-11rem';
                this.recalculateMapHeight(element, map_image, 0.37);
            } else if (width >= ipad_mini && width < container_size) {
                element.style.left = "50%";
                element.style.transform = "translateX(-50%) scale(0.55)";
                // element.style.marginLeft = '-9rem';
                this.recalculateMapHeight(element, map_image, 0.55);
            }
            // alert('width is ' + width);
        } else {
            if (element) {
                if (element.style.removeProperty) {
                    element.style.removeProperty('transform');
                    element.style.removeProperty('left');
                    element.style.removeProperty('margin-top');
                    element.parentElement.parentElement.style.removeProperty('margin-bottom');
                    element.style.removeProperty('height');
                } else {
                    element.style.removeAttribute('transform');
                    element.style.removeAttribute('left');
                    element.style.removeAttribute('margin-top');
                    element.parentElement.parentElement.style.removeAttribute('margin-bottom');
                    element.style.removeAttribute('height');
                }

                if (element.style.removeProperty) {
                    element.style.removeProperty('margin-left');
                } else {
                    element.style.removeAttribute('margin-left');
                }
            }

            if (map_image) {
                if (map_image.style.removeProperty) {
                    map_image.style.removeProperty('height');
                } else {
                    map_image.style.removeAttribute('height');
                }
            }
            // this.recalculateMapHeight(element, map_image);
        }
    }

    recalculateMapHeight(map, image, scale) {
        if (map && image) {
            // console.log('parnet: ', image);
            // let map_height = map.getBoundingClientRect().height;
            // let header = parent.getElementsByClassName('map__content')[0];
            let image_height = $(image).outerHeight(true);
            map.style.height = image_height + 'px';
            //difference between original height and this height, then setting the margin top to be - what this is.
            let diff = this.default_height - (image_height * scale);
            let margin_top
            if (scale === 0.55) {
                margin_top = -140;
            } else if (scale === 0.7) {
                margin_top = -235.9;
            } else {
                margin_top = -235.9;
            }

            map.style.marginTop = margin_top + "px";
            map.parentElement.parentElement.style.marginBottom = margin_top + "px"

            // console.log('map height: ' + map_height + " header_height: " + header_height);
        }
    }
}

export default {
    init() {
        if (document.querySelector('.map-smb-landscape')) {
            Array.from(document.querySelectorAll('.map-smb-landscape'))
                .forEach(smbMapSection => {
                    let map = smbMapSection.querySelector('.map');
                    new MapSMBLandscape(map, 630);
                });
        }
    },
}
