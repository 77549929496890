import Typewriter from 'typewriter-effect/dist/core';

export default {
    init() {
        let el = document.querySelector('.header--2023-refresh .header__heading');

        if (!el) return;

        let typewriterEl = el.querySelector('.typewriter');

        if (!typewriterEl) return;

        let strings = typewriterEl.dataset.strings;
        strings = JSON.parse(strings);

        // Set a minimum height with the longest word to prevent layout shift
        let h = getComputedStyle(el);
        h = h.getPropertyValue('height');

        el.style.minHeight = h;

        typewriterEl.typewriter = new Typewriter(typewriterEl, {
            strings: strings,
            autoStart: true,
            loop: true,
            pauseFor: 5000
        });

        typewriterEl.classList.add('init');
    }
}
