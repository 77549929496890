// Transparent Video - header
export default {
    init: function() {

        window.addEventListener('load', e => {
            if (document.getElementById('output')) {
                var outputCanvas = document.getElementById('output'),
                output = outputCanvas.getContext('2d'),
                bufferCanvas = document.getElementById('buffer'),
                buffer = bufferCanvas.getContext('2d'),
                video = document.getElementById('video'),
                width = outputCanvas.width,
                height = outputCanvas.height,
                interval = 20;

                // Play the video
                video.play();

                setInterval( function () {
                    buffer.drawImage(video, 0, 0);
        
                    var image = buffer.getImageData(0, 0, width, height),
                    imageData = image.data,
                    alphaData = buffer.getImageData(0, height, width, height).data;

                    for (let i = 3; i < imageData.length; i += 4) {
                        imageData[i] = alphaData[i-1];
                    }

                    output.putImageData(image, 0, 0, 0, 0, width, height);

                }, interval);
            }
        });
    }
}
