export default {
    styles: [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9ea0b7"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#f9faff"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ebecff"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c5c8e0"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ced1e8"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#d9dbf2"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#d9dbf2"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#d9dbf2"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#d9dbf2"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#898bac"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#9ea0b7"
                }
            ]
        }
    ],
    init: function () {
        // only fetch the google maps js if needed
        if (document.querySelector('.address-map[data-lat][data-lng], .event-map[data-lat][data-lng]')) {
            this.enqueueGoogleMaps();
        }
    },
    enqueueGoogleMaps: function () {
        let s = document.createElement('script'); s.type = 'text/javascript';
        s.src = `https://maps.googleapis.com/maps/api/js?key=${wordpressGlobals.googleMapsApiKey}&callback=window.codat.initMaps`;
        s.async = 1;
        s.defer = 1;
        let c = document.getElementsByTagName('script')[0];
        c.parentNode.insertBefore(s, c);
    },
    initMaps: function () {
        this.contactMaps();
        this.eventMaps();
    },
    contactMaps: function () {
        // address-map
        if (document.querySelector('.address-map[data-lat][data-lng]')) {
            Array.from(document.querySelectorAll('.address-map[data-lat][data-lng]')).forEach( el => {
                let location = new google.maps.LatLng(el.dataset.lat, el.dataset.lng);
                let map = document.createElement('div');
                let markerIcon = {
                    url: '/wp-content/themes/class/dist/images/map-marker@2x.png',
                    size: new google.maps.Size(189, 189),
                    anchor: new google.maps.Point(47, 47),
                    scaledSize: new google.maps.Size(94, 94)
                };

                el.googleMap = new google.maps.Map(map, {
                    zoom: 17,
                    center: location,
                    disableDefaultUI: true,
                    styles: this.styles,
                });

                el.googleMap.markers = [];
                el.googleMap.markers.push(new google.maps.Marker({
                    position: location,
                    icon: markerIcon,
                    map: el.googleMap
                }));

                el.insertAdjacentElement('afterbegin', map);
            });
        }
    },
    eventMaps: function () {
        // event-details
        if (document.querySelector('.event-map[data-lat][data-lng]')) {
            Array.from(document.querySelectorAll('.event-map[data-lat][data-lng]')).forEach( el => {
                let location = new google.maps.LatLng(el.dataset.lat, el.dataset.lng);
                let map = document.createElement('div');
                let markerIcon = {
                    url: '/wp-content/themes/class/dist/images/event-mapmarker.svg',
                    size: new google.maps.Size(33, 41),
                };

                el.googleMap = new google.maps.Map(map, {
                    center: location,
                    zoom: 12,
                    styles: this.styles,
                    disableDoubleClickZoom: true,
                    disableDefaultUI: true,
                    scaleControl: true,
                    rotateControl: true,
                    fullscreenControl: true
                });

                el.googleMap.markers = [];
                el.googleMap.markers.push(new google.maps.Marker({
                    position: location,
                    icon: markerIcon,
                    map: el.googleMap
                }));

                el.insertAdjacentElement('afterbegin', map);
            });
        }
    }
}
