import Cookies from 'js-cookie';

// Gated Video (email)

export default {

    init() {

        // Gated Video Block
        document.querySelectorAll('.gated-video-section').forEach( element => {
            // check for cookie
            // if cookie show video

            let mediaContainer = element.querySelector('.media-container');
            let videoElement = document.createElement('video');

            videoElement.src = mediaContainer.dataset.src,
            mediaContainer.removeAttribute('data-src');
            videoElement.setAttribute('type', mediaContainer.dataset.mimeType);
            mediaContainer.removeAttribute('data-mime-type');
            videoElement.controls = 1;

            let showVideo = () => {
                mediaContainer.classList.add('no-overlay');
                mediaContainer.insertAdjacentElement('beforeend', videoElement);
            };

            if (typeof(Cookies.get('codat_gste')) != "undefined") {
                showVideo();
                return;
            }

            let interval = setInterval(function () {
                if (typeof(Cookies.get('codat_gste')) != "undefined") {
                    showVideo();
                    clearInterval(interval);
                }
            }, 1000);
        });

        // on succesfull form submit set cookie, show video
        $(document).on( 'cf.form.submit', function (event, data) {
            var $form = data.$form;
            var formId = $form.attr('id');

            // if form is Gated Video (Email) [caldera_form id="CF5f982f4225e9d"]
            if (formId.substring(0, (formId.length - 2)) == 'CF5f982f4225e9d') {        
                if ($form.data().Parsley.isValid()) {
                    Cookies.set('codat_gste', 1, { expires: 14 });
                }
                return;
            }

        });
    }
}
