export const MoveSlideToEnd  = (swiper) => {

    let newSlides = Array.from(swiper.slides);
    let newLast = newSlides.shift();

    newSlides.push(newLast);

    newSlides = Array.from(newSlides).map((el, idx) => {
        el.classList.remove('swiper-slide-prev', 'swiper-slide-next');
        // el.classList.toggle('show', el.classList.contains('swiper-slide-active'));
        return el;
    });

    swiper.el.classList.toggle('disable-transition', true);
    swiper.once('update', function () {
        var swiper = this;
        // remove disable class after update
        setTimeout(function () {
            swiper.el.classList.toggle('disable-transition', false);
        }, 500);
    });

    swiper.wrapperEl.innerHTML = "";
    newSlides.forEach(el => {
        swiper.wrapperEl.insertAdjacentElement('beforeend', el);
    });

    swiper.slideTo(1, 0, false);
    swiper.update();
};
