/** Greenhouse Handler */

export default {
    init() {
        if (typeof Grnhse === 'undefined') {
            return;
        }

        // Size Greenhouse iframe
        window.addEventListener('message', e => {
            if (e.origin === Grnhse.Settings.targetDomain && typeof e.data === 'number') {
                let iframe = document.getElementById('grnhse_iframe');
                iframe.height = e.data;
            }
        });

        // Detect a successful submission from the Greenhouse iframe
        window.addEventListener('message', e => {
            if (e.origin === Grnhse.Settings.targetDomain && e.data === 'greenhouse.confirmation') {
                console.log('Greenhouse application confirmation');
            }
        }, false);
    }
}
