/** Codat Map */

export default {
    /**
     * Height (desktop of the map.)
     */
    default_height: 0,

    /**
     * Opening button div depending which button is clicked.
     * @param {String} map_class Name of element that contains the map picture
     * @param {String} button_class Name of class for the button element.
     */
    init(map_class, button_class, default_height = 0) {
        const WINDOW_WIDTH = window.innerWidth;
        // console.log('inital window width is: ' + WINDOW_WIDTH);

        // console.log('map is working')
        var map_parent = document.getElementsByClassName('map')[0]

        let map = document.querySelector('.' + map_class);

        var map_image = document.querySelector('.' + map_class + " img");

        this.default_height = default_height;

        // console.log('map: ', map_parent)

        // var map = document.getElementsByClassName('map__image')[0];
        this.resizeMap(map, window.innerWidth, map_image);

        $(window).resize(() => {
            this.resizeMap(map, window.innerWidth, map_image);
        });

        const container_size = 1240

        if (map && window.innerWidth >= container_size) {
            // console.log('map class list', map.classList);
            // if(map.classList.contains('map__image--single')) {
            var markers = map.getElementsByClassName('map__div');
            var buttons = map.getElementsByClassName(button_class);
            var pulse_divs = map.getElementsByClassName('map__pulse-div');

            //when we hover over the buttons start pulsing
            for (let i = 0; i < buttons.length; i++) {
                let button = buttons[i];
                $(button).mouseenter(() => {
                    let clicked = button.getAttribute('data-clicked');
                    if (!button.classList.contains('map__pulse-div-active') && clicked == "false") {
                        button.classList.add('map__pulse-div-active', 'map__btn-active');
                    }
                })

                $(button).mouseleave(() => {
                    let clicked = button.getAttribute('data-clicked');
                    if (clicked == "false") {
                        button.classList.remove('map__pulse-div-active');
                        button.classList.remove('map__btn-active');
                    }
                })
            }

            // console.log("Outer: count has " + markers.length + " divs");
            /*$('body').bind('click', function(e) {
                if($(e.target).closest('.map__div').length == 0) {
                    // click happened outside of menu, hide any visible menu items
                    for(let i = 0; i < markers.length; i++) {
                        let info = markers[i].getElementsByClassName('map__info')[0];
                        if(!info.classList.contains('map__info--member')) {
                            info.classList.remove('map__info--visible');
                        }
                    }

                    //closing all the buttons.
                    for(let i = 0; i < buttons.length; i++) {
                        buttons[i].classList.remove('map__btn-active');
                        buttons[i].classList.remove('map__pulse-div-active');
                        buttons[i].setAttribute("data-clicked", "false");
                    }
                } else {
                    console.log('Clicked map__info');
                }
            });*/

            for (let i = 0; i < markers.length; i++) {
                // console.log('looping 1 UwU')
                let marker = markers[i];
                let button = marker.getElementsByClassName('map__btn')[0];
                button.addEventListener('click', (e) => {

                    var userAgent = navigator.userAgent.toLowerCase();

                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') != -1) {
                        if (ua.indexOf('chrome') > -1) {
                            //if chrome do nothing.
                        } else {
                            //if safari add item to end because of stupid browser stacking order issues...
                            $(marker).appendTo(map);
                        }
                    }

                    if (window.document.documentMode) {
                        $(marker).appendTo(map);
                    }
                    // $(marker).insertAfter($('.p :last-child'))


                    //when the button is clicked, add the activate class
                    let clicked = button.getAttribute('data-clicked');

                    //toggling clicked
                    if (clicked == "true") {
                        button.setAttribute("data-clicked", "false");
                    } else {
                        button.setAttribute("data-clicked", "true");
                    }

                    clicked = button.getAttribute('data-clicked');

                    if (clicked == "true") {
                        button.classList.add('map__btn-active')
                        button.classList.add('map__pulse-div-active');
                    } else {
                        button.classList.remove('map__btn-active')
                        button.classList.remove('map__pulse-div-active');
                    }


                    let info = marker.getElementsByClassName('map__info')[0];
                    info.classList.toggle('map__info--visible');
                    // console.log('Marker clicked!!!');

                    // console.log("Inner: count has " + markers.length + " divs");

                    // //going through all the other buttons and closing them.
                    // let other_buttons = document.getElementsByClassName('map__btn');
                    // for(let j = 0; j < other_buttons.length; j++) {
                    //     if(other_buttons[j] !== button) {
                    //         other_buttons[j].classList.remove('map__btn-active');
                    //         other_buttons[j].classList.remove('map__pulse-div-active');
                    //         other_buttons[j].setAttribute("data-clicked", "false");
                    //     }
                    // }

                    // //going through and closing other map markers
                    // for(let j = 0; j < markers.length; j++) {
                    //     let inner_marker = markers[j];
                    //     let inner_info = inner_marker.getElementsByClassName('map__info')[0];
                    //     let inner_button = inner_marker.getElementsByClassName('map__btn')[0];

                    //     inner_button.classList.remove('map__btn--active');

                    //     //if the marker is not the currently selected marker, close it's info.
                    //     if(marker !== inner_marker) {
                    //         inner_info.classList.remove('map__info--visible');
                    //         inner_info.classList.remove('map__info--member')
                    //     }

                    //     // console.log('looping UwU');
                    // }
                })
            }
            // }
        }
    },

    //margin right to centre contents (when origin centre) -36rem
    //.map__image * { margin-left: -36rem }
    resizeMap(element, width, map_image) {
        // console.log("Screen width: " + width);
        if (width < 1200 && element) {
            const small_phone = 350;
            const iphone_6_plus = 414;
            const ipad_mini = 768
            const container_size = 1240;
            // console.log('mobile')
            if (width >= small_phone && width < iphone_6_plus) {
                // console.log('iPhone 6');
                // $(element).css({transform = "scale(0.3)"});
                element.style.left = "50%";
                element.style.transform = "translateX(-50%) scale(0.275)";
                // element.style.marginLeft = '-11rem';
                this.recalculateMapHeight(element, map_image, 0.275);
            } else if (width >= iphone_6_plus && width < ipad_mini) {
                element.style.left = "50%";
                element.style.transform = "translateX(-50%) scale(0.37)";
                // element.style.marginLeft = '-11rem';
                this.recalculateMapHeight(element, map_image, 0.37);
            } else if (width >= ipad_mini && width < container_size) {
                element.style.left = "50%";
                element.style.transform = "translateX(-50%) scale(0.55)";
                // element.style.marginLeft = '-9rem';
                this.recalculateMapHeight(element, map_image, 0.55);
            }
            // alert('width is ' + width);
        } else {
            if (element) {
                if (element.style.removeProperty) {
                    element.style.removeProperty('transform');
                    element.style.removeProperty('left');
                    element.style.removeProperty('margin-top');
                    element.parentElement.parentElement.style.removeProperty('margin-bottom');
                    element.style.removeProperty('height');
                } else {
                    element.style.removeAttribute('transform');
                    element.style.removeAttribute('left');
                    element.style.removeAttribute('margin-top');
                    element.parentElement.parentElement.style.removeAttribute('margin-bottom');
                    element.style.removeAttribute('height');
                }

                if (element.style.removeProperty) {
                    element.style.removeProperty('margin-left');
                } else {
                    element.style.removeAttribute('margin-left');
                }
            }

            if (map_image) {
                if (map_image.style.removeProperty) {
                    map_image.style.removeProperty('height');
                } else {
                    map_image.style.removeAttribute('height');
                }
            }
            // this.recalculateMapHeight(element, map_image);
        }
    },
    recalculateMapHeight(map, image, scale) {
        if (map && image) {
            // console.log('parnet: ', image);
            // let map_height = map.getBoundingClientRect().height;
            // let header = parent.getElementsByClassName('map__content')[0];
            let image_height = $(image).outerHeight(true);
            map.style.height = image_height + 'px';
            //difference between original height and this height, then setting the margin top to be - what this is.
            let diff = this.default_height - (image_height * scale);
            let margin_top
            if (scale === 0.55) {
                margin_top = -140;
            } else if (scale === 0.7) {
                margin_top = -235.9;
            } else {
                margin_top = -235.9;
            }

            map.style.marginTop = margin_top + "px";
            map.parentElement.parentElement.style.marginBottom = margin_top + "px"

            // console.log('map height: ' + map_height + " header_height: " + header_height);
        }
    }
}
