class EventFilter {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError('Invalid element');
        }

        this.container = element;
        this.dropdown = this.container.querySelector('.event-category-selector');

        if (this.dropdown) {
            this.dropdown.addEventListener('change', this.showSelectedCategory.bind(this));

            this.hideAllCategory();
            this.showCategory('all');
        }
    }

    hideAllCategory() {
        const grid = this.container.querySelector('.grid-upcoming-posts');
        grid.querySelectorAll('.event-category').forEach(el => {
            el.classList.toggle('hidden', true);
        });
    }

    showCategory(category) {
        let grid = this.container.querySelector('.grid-upcoming-posts');
        grid.querySelector(`.event-category.event-category-${category}`)
            .classList.toggle('hidden', false);
    }

    showSelectedCategory(event) {
        this.hideAllCategory();
        this.showCategory(event.currentTarget.value);
    }
}

export default {
    init() {
        if (document.querySelector('.upcoming-events-section')) {
            Array.from(document.querySelectorAll('.upcoming-events-section'))
                .forEach(el => {
                    el.filter = new EventFilter(el);
                });
        }
    }
}
