import Swiper, { EffectFade } from "swiper";
import Accordion from "./accordion";

class AccordionSlider {
    constructor(element) {
        if (!(element instanceof HTMLElement)) {
            throw new TypeError('Invalid element');
        }

        this.section = element;
        this.swiperElement = this.section.querySelector('.swiper-container');
        this.accordionElement = this.section.querySelector('.accordion');

        this.initSwiper();
        this.initAccordion();
    }

    initSwiper() {
        this.swiper = new Swiper(this.swiperElement, {
            modules: [EffectFade],
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            allowTouchMove: false
        });
    }

    initAccordion() {
        this.accordion = new Accordion(this.accordionElement, 'click');
        this.accordionElement.addEventListener('accordion:changed', () => {
            this.swiper.slideTo([...this.accordion.items].indexOf(this.accordion.active));
        });
    }
}

export default {
    init() {

        if (document.querySelector('.section--accordion-slider')) {
            document.querySelectorAll('.section--accordion-slider')
                .forEach(el => {
                    el.AccordionSlider = new AccordionSlider(el);
                });
        }
    },
}
