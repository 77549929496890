/**
 * Nav Taxonomy Category
 *
 * Mobile toggle for navigation menu
 *
 * todo: can this be replaces for details html tag? (accordion)
 */

export default {
  init () {
    Array.from(document.querySelectorAll('.nav.nav--terms') ?? [])
      .forEach(el => {
        const toggleBtn = el.querySelector('[data-toggle-list]');

        // Toggle open class when button is clicked
        toggleBtn.addEventListener('click', e => {
          e.preventDefault();
          toggleBtn.classList.toggle('open');
        });

        // Remove the open class once the event is initiated
        toggleBtn.classList.toggle('open', false);
      });
  }
}
