import Swiper, { Navigation, Pagination, Autoplay, EffectFade, Lazy, EffectCreative, Grid } from "swiper";
import { MoveSlideToEnd } from "./MoveSlideToEnd";

export default {
    init() {
        this.initTabbedSwiper();
        this.initPartnerCardCarousel();
        this.initPartnerCarousel();
        this.initTimelineSwiper();
        this.initCaseStudyQuoteSwiper();
        this.initReviewBarSwiper();
        this.initIntegrationsSlider();
        this.initIntegrationsCarousel();
        this.initLogosCarousel();
        this.initMediaCarousel();
        this.initLogoGridSwiper();
        this.initQuoteSliderSwiper();
        this.initCaseStudyArchiveHeaderSwiper();
    },
    /**
     * Initialize Tabbed Swipers
     *
     * used on Case Studies Archive Page /case-studies/
     */
    initTabbedSwiper() {
        // console.log('[Swipers] initTabbedSwiper()');
        Array.from(document.querySelectorAll('.swiper-container.tabbed-swiper') ?? [])
            .forEach( el => {
                let menuTabLabels = JSON.parse(el.querySelector('.swiper-pagination').dataset.labels);

                // Set based on client width, used to enable scrolling and auto height on mobile
                let isMobile = document.body.clientWidth < 1240; // 768

                el.swiper = new Swiper(el, {
                    modules: [Pagination, EffectFade],
                    // autoHeight: isMobile,
                    autoHeight: true,
                    spaceBetween: 20,
                    allowTouchMove: isMobile,
                    autoplay: false,
                    loop: false,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        renderBullet: function (index, className) {
                            return "<span class='" + className + "'>" + (menuTabLabels[index]) + "</span>";
                        },
                    },
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                });

                // Create on change event listener for dropdown
                let tabbedDropdown = el.querySelector('.tabbed-dropdown-select');

                if (tabbedDropdown) {
                    let touch_timer, touch_timer_interval = 1500;
                    function enable_touch() {
                        // console.log('re-enabling touch events');
                        el.swiper.allowTouchMove = true;
                    }

                    tabbedDropdown.addEventListener('change', e => {
                        el.swiper.slideTo(e.target.value, 500);
                    });

                    tabbedDropdown.addEventListener('showDropdown', () => {
                        // disabling the touch move when we open the dropdown as
                        //  was clicking through when disabled for some reason.
                        if (isMobile) {
                            clearTimeout(touch_timer)
                            el.swiper.allowTouchMove = false;
                        }
                    });

                    tabbedDropdown.addEventListener('hideDropdown', () => {
                        // setting timeout just in case the user has selected nothing, in which case re-enable the touch events
                        if (isMobile) {
                            clearTimeout(touch_timer);
                            touch_timer = setTimeout(enable_touch, touch_timer_interval);
                        }
                    });

                    // Update dropdown text when slider changes
                    el.swiper.on('slideChange', () => {
                        let newSlideIndex = el.swiper.activeIndex;
                        let newSlideLabel = menuTabLabels[newSlideIndex];

                        let newOptionHtml = `<option value="${newSlideIndex}" >${newSlideLabel}</option>`

                        let choicesEl = el.querySelector('.choices__list.choices__list--single');

                        let dataId = choicesEl.children[0].dataset.id;
                        let newChoicesHtml = `<div class="choices__item choices__item--selectable" data-item="" data-id="${dataId++}" data-value="${newSlideIndex}" data-custom-properties="null" aria-selected="true" >${newSlideLabel}</div>`;

                        // Set new innerHtml
                        el.querySelector('.dropdown-select').innerHTML = newOptionHtml;
                        choicesEl.innerHTML = newChoicesHtml;
                    });

                    el.swiper.on('slideChangeTransitionEnd', () => {
                        // re-enabling touch events once dropdown has been closed.
                        if (isMobile) {
                            clearTimeout(touch_timer);
                            el.swiper.allowTouchMove = true;
                        }
                    });
                }
            });
    },
    /**
     * Initialize Partner Card Carousel
     */
    initPartnerCardCarousel() {
        // console.log('[Swipers] initPartnerCardCarousel()');
        Array.from(document.querySelectorAll('.swiper-container.partner-cards-carousel') ?? [])
            .forEach( el => {
                el.swiper = new Swiper(el, {
                    modules: [Autoplay, Navigation],
                    allowTouchMove: true,
                    autoplay: {
                        delay: 6000,
                    },
                    centeredSlides: true,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            });
    },
    /**
     * Initialize Partner Carousel
     *
     * Carousel of partner logos
     *
     * used on the Partners Page /partners/
     */
    initPartnerCarousel() {
        // console.log('[Swipers] initPartnerCarousel()');
        Array.from(document.querySelectorAll('.swiper-container.partner-carousel') ?? [])
            .forEach( el => {
                el.swiper = new Swiper(el, {
                    modules: [Autoplay],
                    autoplay: {
                        delay: 1,
                        disableOnInteraction: false,
                    },
                    allowTouchMove: false,
                    speed: 7000,
                    loop: true,
                    centeredSlides: true,
                    loopedSlides: null,
                    slidesPerView: 'auto',
                    spaceBetween: 50,
                });
            });
    },
    /**
     * Initialize Timeline Swipers
     *
     * used on Banking Solution Page /solutions/banking/
     */
    initTimelineSwiper() {
        // console.log('[Swipers] initTimelineSwiper()');
        Array.from(document.querySelectorAll('.swiper-container.timeline-swiper') ?? [])
            .forEach( el => {
                el.swiper = new Swiper(el, {
                    modules: [Navigation],
                    allowTouchMove: true,
                    autoplay: false,
                    centeredSlides: true,
                    loop: false,
                    slidesPerView: 2,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    spaceBetween: 0,
                });

                el.swiper.on('slideChange', () => {
                    let newSlideIndex = el.swiper.activeIndex;

                    // Update active timeline_item
                    let timelineEl = el.parentElement.querySelector('.timeline');
                    let timelineItems = timelineEl.querySelectorAll('.timeline__item');
                    let eventTarget = timelineItems[newSlideIndex];

                    // remove active and post-active class from all items
                    timelineItems.forEach( el => {
                        if (el.classList.contains('post-active')) {
                            el.classList.toggle('post-active');
                        }
                        if (el.classList.contains('active')) {
                            el.classList.toggle('active');
                        }
                    });

                    // find clicked item, set class of previous items
                    for (let i = 0; i < timelineItems.length; i++) {
                        if (timelineItems[i] === eventTarget) {
                            timelineItems[i].classList.toggle('active');
                            break;
                        }
                        timelineItems[i].classList.toggle('post-active');
                    }
                });
            });
    },
    /**
     * Initialize Case Study Quote Swipers
     *
     * used on the homepage /
     */
    initCaseStudyQuoteSwiper() {
        // console.log('[Swipers] initCaseStudyQuoteSwiper()');
        Array.from(document.querySelectorAll('.swiper-container.case-study-quote-swiper') ?? [])
            .forEach( el => {
                el.swiper = new Swiper(el, {
                    modules: [Pagination, Navigation, EffectFade],
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            });
    },
    /**
     * Initialize Review Bar Swipers
     *
     * used on
     */
    initReviewBarSwiper() {
        // console.log('[Swipers] initReviewBarSwiper()');
        Array.from(document.querySelectorAll('.swiper-container.reviews-swiper') ?? [])
            .forEach( el => {
                el.swiper = new Swiper(el, {
                    modules: [Autoplay],
                    allowTouchMove: false,
                    autoplay: {
                        delay: 3000,
                    },
                    loop: true,
                    slidesPerView: 1,
                });
            });
    },
    /**
     * Integrations Slider
     */
    initIntegrationsSlider() {
        // console.log('[Swipers] initIntegrationsSlider()');
        Array.from(document.querySelectorAll('.swiper-integrations-slider') ?? [])
            .forEach(el => {
                el.swiper = new Swiper(el, {
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                });
            });
    },
    /**
     * Integrations Carousel
     */
    initIntegrationsCarousel() {
        // console.log('[Swipers] initIntegrationsCarousel()');
        Array.from(document.querySelectorAll('.swiper-integrations-carousel') ?? [])
            .forEach(el => {

                let totalSlides = Array.from(el.querySelectorAll('.swiper-slide')).length;
                let loop = totalSlides > 3;

                el.swiper = new Swiper(el, {
                    modules: [Autoplay],
                    autoplay: loop ? {
                        delay: 1,
                        disableOnInteraction: false,
                        reverseDirection: el.dataset.reverseDirection === 'true',
                    } : false,
                    allowTouchMove: !loop,
                    speed: 10000,
                    loop: loop,
                    slidesPerView: 'auto',
                    spaceBetween: 32,
                });
            });
    },
    /**
     * Logos Carousel
     *
     * Use: Home Header, Logos Carousel
     */
    initLogosCarousel() {
        if (document.querySelector('.swiper-logos-carousel')) {

            // console.log('[Swipers] initLogosCarousel()');

            let lists = Array.from(document.querySelectorAll('.list--logos'));

            // Filter lists containing swiper
            lists = lists.filter((list) => list.querySelector('.logos-carousel-wrapper'));

            lists.forEach(list => {

                let carouselItems = Array.from(list.querySelectorAll('.swiper-logos-carousel'));

                carouselItems.forEach(el => {

                    el.swiper = new Swiper(el, {
                        modules: [Lazy],
                        preloadImages: false,
                        lazy: {
                            loadPrevNext: true,
                        },
                        allowTouchMove: false,
                        speed: 3000,
                        direction: 'vertical',
                        slidesPerView: 1,
                        height: 64,
                        loop: true,
                    });

                    el.swiper.on('slideChangeTransitionStart', function() {
                        // Add fade-out class to current slide
                        // Remove fade-in class to next slide
                        let swiper = this;
                        swiper.slides[swiper.activeIndex -1 ].classList.toggle('show', false);
                        swiper.slides[swiper.activeIndex].classList.toggle('show', true);
                    });

                    el.swiper.on('slideChangeTransitionEnd', function() {

                        let swiper = this;

                        // trigger offset +1 because of the overlapping horizontal cards on mobile
                        if (swiper.activeIndex === 2){
                            // el.swiper.autoplay.stop();
                            MoveSlideToEnd(swiper);
                            // el.swiper.autoplay.start();
                        }
                    });

                    el.swiper.slideNext(3000);
                });

                // Cycle through items in random order
                list.autoplay = setInterval(() => {
                    // After a set period, randomly select a slider from the list and go to next slide
                    let rand = Math.floor(Math.random() * carouselItems.length);
                    carouselItems[rand].swiper.slideNext();
                }, 1500);
            });
        }
    },
    initMediaCarousel() {
        Array.from(document.querySelectorAll('.swiper-media-carousel') ?? [])
            .forEach(el => {
                el.swiper = new Swiper(el, {
                    modules: [Autoplay, Pagination, EffectCreative],
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true
                    },
                    slideToClickedSlide: true,
                    grabCursor: true,
                    initialSlide: 1,
                    slidesPerView: 1240 / 925,
                    centeredSlides: true,
                    loop: true,
                    effect: 'creative',
                    creativeEffect: {
                        prev: {
                            opacity: 0.6,
                            scale: 782 / 925,
                            translate: [`${-158 / 640 * 100}%`, 0, -20],
                        },
                        next: {
                            opacity: 0.6,
                            scale: 782 / 925,
                            translate: [`${158 / 640 * 100}%`, 0, -10],
                        }
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                });
            });
    },
    initLogoGridSwiper() {
        Array.from(document.querySelectorAll('.swiper.swiper-logo-grid') ?? [])
            .forEach(el => {

                let options = {
                    init: window.innerWidth <= 768,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 64,
                    initialSlide: 1,
                    on: {
                        'resize': (swiper) => {
                            if (!(window.innerWidth <= 768)) {
                                swiper.destroy();
                            }
                        }
                    }
                };

                el.swiper = new Swiper(el, options);

                window.addEventListener('resize', () => {
                    if (window.innerWidth <= 768) {
                        if (el.swiper instanceof Swiper) {
                            el.swiper.init();
                        } else {
                            el.swiper = new Swiper(el, options);
                        }
                    }
                });
            });
    },
    initQuoteSliderSwiper() {
        Array.from(document.querySelectorAll('.quote-slider-swiper') ?? [])
            .forEach(el => {
                el.swiper = new Swiper(el, {
                    modules: [Pagination, EffectFade],
                    spaceBetween: 20,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true,
                    },
                });
            });
    },
    initCaseStudyArchiveHeaderSwiper() {
        Array.from(document.querySelectorAll('.swiper-container.case-study-archive-header-swiper') ?? [])
            .forEach(el => {
                el.swiper = new Swiper(el, {
                    modules: [Grid],
                    spaceBetween: 40,
                    grabCursor: true,
                    slidesPerView: 1.5,
                    grid: {
                        fill: 'row',
                        rows: 1,
                    },
                    breakpoints: {
                        992: {
                            spaceBetween: 48,
                            slidesPerView: 2,
                            grid: {
                                fill: 'row',
                                rows: 2,
                            },
                        },
                    },
                });
            });
    }
}
