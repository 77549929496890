// Initiate Timelines
export default {
    init: function() {

        document.querySelectorAll('.timeline').forEach( (el, timelineIndex) => {
            let timelineItems = el.querySelectorAll('.timeline__item');

            let firstItemOffset = timelineItems[0].offsetWidth / 2;
            let lastItemOffset = timelineItems[timelineItems.length - 1].offsetWidth / 2;

            // calc(100% - {})
            let number = (firstItemOffset + lastItemOffset) + 'px';
            document.querySelectorAll('.timeline__progress')[timelineIndex].style.width = "calc(100% - " + number + ")";

            // set first timeline item to active
            timelineItems[0].classList.toggle('active');

            // timeline item event listenter
            timelineItems.forEach( (el, itemIndex) => {
                el.addEventListener('click', e => {
                    e.preventDefault();

                    let eventTarget = e.target;

                    // if target is step-marker, get timeline__item
                    if (eventTarget.classList.contains('step-marker')) {
                        eventTarget = eventTarget.parentElement.parentElement;
                    }

                    // remove active and post-active class from all items
                    timelineItems.forEach( el => {
                        if (el.classList.contains('post-active')) {
                            el.classList.toggle('post-active');
                        }
                        if (el.classList.contains('active')) {
                            el.classList.toggle('active');
                        }
                    });

                    // find clicked item, set class of previouse items
                    for (let i = 0; i < timelineItems.length; i++) {
                        if (timelineItems[i] == eventTarget) {
                            timelineItems[i].classList.toggle('active');
                            break;
                        }
                        timelineItems[i].classList.toggle('post-active');
                    }

                    // calculate percent of progress bar
                    let progress = itemIndex / (timelineItems.length - 1) * 100 + "%";

                    // update progress bar
                    document.querySelectorAll('.timeline-progress-bar')[timelineIndex].style.width = progress;

                });
            });
        });
    }
}
